import config from '@/config'
import axios from 'axios'

const { BASE_API } = config;

axios.defaults.baseURL = BASE_API;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default {
    getAuthPath(connector, clientId) {
        return axios.getUri({
            "url": `/auth/${connector.name}/${clientId}`
        })
    },
    async searchClients(search) {
        // this can be set to limit the number of characters required before a search executes
        if (search.length == 0)
            return [];

        try {
            let resp = await axios.get('/clients', {
                params: {
                    search: search
                }
            });
            return resp.data
        } catch (err) {
            throw err.response.data.message
        }
    },
    async getClient(clientId) {
        try {
            let resp = await axios.get(`/clients/${clientId}`);
            return resp.data
        } catch (err) {
            throw err.response.data.message
        }
    },

    async getConnectors(clientId) {
        try {
            let resp = await axios.get(`/clients/${clientId}/connectors`);
            return resp.data
        } catch (err) {
            throw err.response.data.message
        }
    },
    async loginWithCredentials(path, requestId, clientId, username, password, accountType) {
        try {
            let fd = new FormData();

            fd.append('username', username);
            fd.append('password', password);
            fd.append('ie_client_id', clientId);
            fd.append('account_type', accountType)

            let resp = await axios.post(path, fd, {
                params: {
                    req: requestId
                },
            });

            if (resp.data.constructor !== {}.constructor || !('redirectUrl' in resp.data)) {
                resp.data = {
                    'redirectUrl': '',
                };
            }

            return resp.data;
        } catch (err) {
            throw err.response.data.message;
        }
    },
    async sendResetEmail(email, clientId, accountType, connID) {
        let fd = new FormData();
        fd.append('clientID', clientId)
        fd.append('email', email);
        fd.append('accountType', accountType)
        fd.append('connID', connID)

        try {
            let resp = await axios.post(`/email-reset`, fd, {});

            return resp.data;
        } catch (err) {
            throw err.response.data.message;
        }
    },
    // Send a password and token to the authentication service.
    async changePassword(password, token) {
        let fd = new FormData();
        fd.append('password', password);

        try {
            let resp = await axios.post(`/reset-password/${token}`, fd, {});

            return resp.data
        } catch (err) {
            throw err.response.data.message
        }
    },
    async getUserInfo() {
        try {
            let resp = await axios.get(`/userinfo`);
            let { clientId, clientName, account } = resp.data
            let userinfo = {
                'clientId': clientId,
                'clientName': clientName,
                'id': account.id,
                'firstName': account.firstName,
                'lastName': account.lastName,
                'type': account.type,
                signedIn: true,
            };

            if (account.impersonate !== undefined) {
                userinfo.impersonate = {
                    'id': account.impersonate.id,
                    'firstName': account.impersonate.firstName,
                    'lastName': account.impersonate.lastName,
                }
            }

            return userinfo;
        } catch (err) {
            if (err.response.data.status === 401) return null;
            throw err.response.data.message
        }
    },
}
