<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script>

export default {
    name: 'App',
    computed: {
        layout() {
            return (this.$route.meta.layout || "default") + "-layout";
        },
    }
}
</script>
<style>
    @import "https://fonts.googleapis.com/css?family=Material+Icons";

    html {
        overflow-y: auto !important;
    }



    .v-application a {
        font-size: 12px;
        text-decoration: none;
        color: #1976D2;
    }

    .v-application a:hover {
        color: #0256B3;
    }

    .form-submit {
        width: 100%;
    }

    .form-submit .v-btn {
        margin: 0 !important;
    }

    .strike {
        display: flex;
        text-align: center;
    }

    .strike:before,
    .strike:after {
        content: "";
        flex: 1;
        height: 1px;
        background: #539AF8;
        margin: auto 0;
    }
    .strike:before {
        margin-right: 12px;
    }
    .strike:after {
        margin-left: 12px;
    }

    .v-btn:before {
        background: #146EB3 !important;
    }

    .fade-in {
        animation: fadeIn ease .75s;
    }

    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }
</style>
