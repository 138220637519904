import products from '@/api/products'

const getDefaultState = () => {
    return {
        products: [],
    }
};

const state = getDefaultState();

const getters = {
    products: (state) => state.products,
};

const actions = {
    async getProducts({ commit }) {
        try {
            commit('setProducts', await products.getProducts())
        } catch (err) {
            commit('setError', err)
        }
    },
};

const mutations = {
    setProducts: (state, products) => state.products = products || [],


};

export default {
    state,
    getters,
    actions,
    mutations,
}
