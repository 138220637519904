import config from '@/config/'
import axios from 'axios'

const { BASE_API } = config;


axios.defaults.baseURL = BASE_API;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default {
    async getProducts() {
        try {
            let resp = await axios.get(`/products`);

            return resp.data;
        } catch (err) {
            throw err.response.data.message
        }
    },
}
