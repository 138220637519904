import Vue from 'vue'
import Vuex from 'vuex'
import login from '@/store/modules/login'
import products from '@/store/modules/products'
import root from '@/store/modules/root'
import VeeValidate from 'vee-validate';


Vue.use(Vuex);
Vue.use(VeeValidate, { events: 'change' });

export default new Vuex.Store({
  modules: {
      root,
      login,
      products
  }
})
