import store from '@/store'
import {RPSession} from "../session/rp_session"
import axios from 'axios'

function createSessionTimer(){
    const cookieName = 'opbs';
    let sessionState = window.$cookies.get(cookieName);
    let opbsExp = window.$cookies.get("opbsExp").split(":")[0];
    let expireTime = opbsExp === "" ? new Date().getTime()/1000 + 7200 : opbsExp;
    let isPaused = false;
    let clientId = store.getters.userinfo.clientId;
    let accountType = store.getters.userinfo.type;
    let url = getLogoutURL(clientId, accountType);
    let sessionInterval = setInterval(function() {
        let currentTime = new Date().getTime() / 1000;
        if (window.$cookies.get("opbsExp")) {
            opbsExp = window.$cookies.get("opbsExp").split(":")[0];
        }

        if (opbsExp !== "") {
            expireTime = opbsExp;
        }
        if (!isPaused) {
            // if session state is empty, log user out
            let currentSessionState = window.$cookies.get(cookieName);
            if (currentSessionState == null) {
                clearInterval(sessionInterval);
                window.location.href = url;
                return;
            }
            if (currentTime >= expireTime - 120) {
                isPaused = true;
                let rp = new RPSession(
                    clientId,
                    sessionState,
                    window.location.hostname,
                    {
                        reauthenticate: async function () {
                            axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
                            await axios.get('userinfo');
                            expireTime = window.$cookies.get("opbsExp");
                            isPaused = false;
                        }
                });
                rp.alertMessage(window.parent, "Warning", "Your session will expire in 2 minutes due to inactivity. Please click ok to extend your session.");
                return;
            }

            // if session states do not match, reload components somehow
            if (currentSessionState !== sessionState) {
                window.location.reload();
            }
        } else if (isPaused === true && currentTime < expireTime - 120) {
            let warning = document.getElementsByName("alert");
            if (warning.length > 0) {
                warning[0].remove();
                isPaused = false;
            }
        } else if (currentTime >= expireTime) {
            clearInterval(sessionInterval);
            window.location.href = url;
        }
    }, 5*1000);

    window.addEventListener("beforeunload", function () {
        clearInterval(sessionInterval);
    });

    return sessionInterval;
}

function getLogoutURL(clientId, accountType) {

    let url = '/logout';
    if (clientId != "") {
        url += '?ied_client_id='+clientId;
        if (accountType != "") {
            url += '&account_type='+accountType;
        }
    }
    return url;
    
}

export {createSessionTimer};
