import auth from '@/api/auth/'
import config from '@/config'

const getDefaultState = () => {
    return {
        requestId: null,
        client: null,
        connectors: [],
        selectedConnector: null,
        userinfo: null,
        illuminateConnector: null,
    }
};

const state = getDefaultState();

const getters = {
    requestId: (state) => state.requestId,
    isClientSelected: (state) => {
        return state.client || null
    },
    selectedClient: (state) => state.client,
    connectors: (state) => {
        return state.connectors.filter(c => c.provider !== "illuminate_google")
    },
    connectorsByAccountType: (state) => (accountType) => {
        return state.connectors.filter(c => c.accountTypes[accountType] === true)
    },
    selectedConnector: (state) => state.selectedConnector,
    enabledAccountTypes: (state) => {
        let enabledAccountTypes = [];
        for (let i = 0; i < state.connectors.length; i++) {
            let accountTypes = state.connectors[i]['accountTypes']
            if (accountTypes['staff'] === true) {
                enabledAccountTypes['staff'] = true
            }
            if (accountTypes['parent'] === true) {
                enabledAccountTypes['parent'] = true
            }
            if (accountTypes['student'] === true) {
                enabledAccountTypes['student'] = true
            }
        }

        return Object.keys(enabledAccountTypes)
    },
    illuminateConnector: (state) => {
        let link = '#';

        if (config.USE_ILLUMINATE_LOGIN) {
            if (state.illuminateConnector != null) {
                link = auth.getAuthPath(state.illuminateConnector, config.ILLUMINATE_CLIENT_ID)
                if (state.requestId != null) {
                    link += `?req=${state.requestId}`
                }
            }

            return link
        }

        let connector = state.connectors.filter(c => c.provider === "illuminate_google");
        if (connector.length) {
            link = auth.getAuthPath(connector[0], state.client.id)
            if (state.requestId != null) {
                link += `?req=${state.requestId}`
            }
        }

        return link
    },
    signedIn: (state) => state.userinfo !== null,
    userinfo: (state) => state.userinfo,
    isImpersonated: (state) => !(state.userinfo.impersonate === undefined),
    displayName: (state, getters) => {
        if (getters.isImpersonated) {
            return `${state.userinfo.impersonate.firstName} ${state.userinfo.impersonate.lastName}`
        }
        return getters.originalName
    },
    originalName: (state) => {
        return `${state.userinfo.firstName} ${state.userinfo.lastName}`
    },
};

const actions = {
    async setClient({ commit }, client){
        commit('setClient', client);
        if (client == null) {
            commit('setConnectors', [])
            return
        }

        try {
            commit('setConnectors', await auth.getConnectors(client.id))
        } catch (err) {
            commit('setError', err)
        }
    },
    setRequestId({ commit }, requestId){
        if (typeof requestId !== "string" || requestId.length === 0) {
            requestId = null
        }

        commit('setRequestId', requestId)
    },
    async getUserInfo({ commit }) {
        try {
            let userinfo = await auth.getUserInfo()
            commit('setUserInfo', userinfo)
        } catch (err) {
            commit('setError', err)
        }
    },
    async fetchIlluminateConnector({ commit, state }) {
        if (state.illuminateConnector != null) {
            return
        }

        try {
            let connectors = await auth.getConnectors(config.ILLUMINATE_CLIENT_ID)
            commit('setIlluminateConnector', connectors[0])
        } catch (err) {
            // fail silently to not spam clients with irrelevant errors
        }
    },
    selectConnector({ commit }, connector) {
        commit('selectConnector', connector)
    },
    resetSession({ commit }) {
        commit('resetSession')
    }
};

const mutations = {
    setClient: (state, client) => {
        let oldClient = state.client

        state.client = client || null

        if (oldClient == null) {
            return
        }

        if (state.client && oldClient.id === state.client.id) {
            return
        }

        state.connectors = []
        state.requestId = null
    },
    setConnectors: (state, connectors) => {
        state.connectors = connectors || []
    },
    selectConnector: (state, connector) => {
        state.selectedConnector = connector
    },
    setIlluminateConnector: (state, connector) => {
        state.illuminateConnector = connector
    },
    setRequestId: (state, requestId) => {
        state.requestId = requestId
    },
    setUserInfo: (state, userinfo) =>  {
        state.userinfo = userinfo
    },
    resetSession: (state) =>  {
        Object.assign(state, getDefaultState())
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}
