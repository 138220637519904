import {createSessionTimer} from "@/session"


const getDefaultState = () => {
    return {
        messages: {
            error: "",
            success: "",
        },
        sessionTimer: null
    }
};

const state = getDefaultState();

const getters = {
    error: (state) => state.error,
    messages: (state) => state.messages,

    sessionTimer: (state) => state.sessionTimer
};

const actions = {
    setError({commit}, message) {
        commit('setError', message)
    },
    setSuccess({commit}, message) {
        commit('setSuccess', message)
    },

    startTimer({commit, state}) {
        if (state.sessionTimer != null) {
            this.dispatch('stopTimer')
        }

        commit('setSessionTimer', createSessionTimer())
    },
    stopTimer({commit, state}) {
        clearInterval(state.sessionTimer)
        commit('setSessionTimer', null)
    }
};

const mutations = {
    setError: (state, message) => state.messages.error = message,
    setSuccess: (state, message) => state.messages.success = message,

    setSessionTimer: (state, timer) => state.sessionTimer = timer,
};

export default {
    state,
    getters,
    actions,
    mutations,
}