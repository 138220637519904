import Vue from 'vue'
import App from './App.vue'
import {
    Defaults,
    EcUpdateTheme,
    EcFrontendLibrary,
    EcFrontendVuetify,
    GetVuetifyInstance
} from '@illuminateeducation/ec-frontend-library'
import '@illuminateeducation/ec-frontend-library/dist/ec-frontend-library.css'
import '@mdi/font/css/materialdesignicons.css'

import store from '@/store'
import router from '@/router.js'

import '@/cookies.js'

import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: process.env.VUE_APP_SENTRY_RELEASE,
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true
            })
        ]
    })
}

Vue.use(EcFrontendLibrary, {
    axios: {
        withCredentials: true,
    },
})

Vue.use(EcFrontendVuetify)

let vuetify = GetVuetifyInstance({
    options: {
        customProperties: true
    },
    iconfont: 'fa',
    theme: {
        dark: false,
        themes: Defaults.ecThemes
    }
})

EcUpdateTheme(Defaults.ecThemes.light) // creates CSS variable equivalents of all variables for use in local components
                                       // with something like the following: var(--fe-primary);
Vue.config.productionTip = false
Vue.prototype.$vuetify = vuetify

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
